<template>

<div class="p-grid">
    <div class="p-col-12">
      <Panel header="Acompanhamento de Log's" style="height: 100%">
        <Toolbar class="mb-1">
          <template #start>
            <ul>
              <li>
                <span class="product-badge status-post">POST</span>
                <b> - CADASTRO DE REGISTRO.</b>
              </li>
              <li class="mt-2">
                <span class="product-badge status-put">PUT</span>
                <b> - ALTERAÇÃO DE REGISTRO.</b>
              </li>
              <li class="mt-2">
                <span class="product-badge status-delete">DELETE</span>
                <b> - REMOÇÃO DE REGISTRO.</b>
              </li>

              <li class="mt-2">
                <span class="product-badge status-get">GET</span>
                <b> - LISTAGEM DE UM OU MAIS REGISTROS.</b>
              </li>
            </ul>
          </template>
          <template #end> </template>
        </Toolbar>
        <DataTable
          :value="allLog"
          :lazy="true"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 30]"
          scrollable
          v-model:filters="filters" 
          ref="dt"
          class="p-datatable-sm"
          dataKey="id"
          :totalRecords="totalRecords"
          :loading="loading"
          @page="onPage($event)"
          @filter="onFilter($event)" 
          filterDisplay="row" 
          responsiveLayout="scroll"
          :globalFilterFields="['descricao', 'nomeOperador', 'dtOperacao', 'operacao', 'endPoint']"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} registros de Log."
        >
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column 
            header="End-Point" 
            field="endPoint"
            ref="endPoint"
            filterField="endPoint"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown 
                v-model="filterModel.value"
                :options="allEndPoints" 
                :filter="true"
                placeholder="Informe o end-point" 
                @change="filterCallback()"
                >
              </Dropdown>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.endPoint }}
            </template>
          </Column>
          <Column 
            header="Descrição" 
            field="descricao"
            ref="descricao"
            filterField="descricao">
           >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Informe a descrição" />
            </template>

            <template #body="slotProps">
              <div class="myBox">
                {{ slotProps.data.descricao }}
              </div>
            </template>
          </Column>
          <Column 
            header="Responsavél" 
            field="nomeOperador"
            ref="nomeOperador"
            filterField="nomeOperador"
          
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Informe o responsável" />
            </template>
            <template #body="slotProps">
              {{ slotProps.data.nomeOperador }}
            </template>
          </Column>
          <Column header="Origem" field="endereco">
            <template #body="slotProps">
              {{ formatIp(slotProps.data.endereco) }}
            </template>
          </Column>
          <Column 
            header="Dt. Operação" 
            field="dtOperacao"
            ref="dtOperacao" 
            filterField="dtOperacao"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="date" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Pesquisar por Data" />
            </template>
            <template #body="slotProps">
              {{ $DateTime.formatarDateTime24hours(slotProps.data.dtOperacao) }}
            </template>
          </Column>

          <Column 
            header="Operação" 
            field="operacao"
            ref="operacao" 
            filterField="operacao"
          
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Informe a operação" />
            </template>
            <template #body="slotProps">
              <span v-if="slotProps.data.operacao === 'POST'" class="product-badge status-post">{{ slotProps.data.operacao
              }}</span>
              <span v-if="slotProps.data.operacao === 'PUT'" class="product-badge status-put">{{ slotProps.data.operacao
              }}</span>
              <span v-if="slotProps.data.operacao === 'DELETE'" class="product-badge status-delete">{{
                slotProps.data.operacao }}</span>
              <span v-if="slotProps.data.operacao === ' GET' || slotProps.data.operacao === 'GET'" class="product-badge status-get">{{
                slotProps.data.operacao }}</span>
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>

  <!--
  <div class="p-grid">
    <div class="p-col-12">
      <Panel header="Consulta de Log">
        <Toolbar class="p-mb-3">
          <template v-slot:left>
            <ul>
              <li>
                <span class="product-badge status-post">POST</span>
                <b> - CADASTRO DE REGISTRO.</b>
              </li>
              <li class="p-mt-2">
                <span class="product-badge status-put">PUT</span>
                <b> - ALTERAÇÃO DE REGISTRO.</b>
              </li>
              <li class="p-mt-2">
                <span class="product-badge status-delete">DELETE</span>
                <b> - REMOÇÃO DE REGISTRO.</b>
              </li>

              <li class="p-mt-2">
                <span class="product-badge status-get">GET</span>
                <b> - LISTAGEM DE UM OU MAIS REGISTROS.</b>
              </li>
            </ul>
          </template>
          <template v-slot:right> </template>
        </Toolbar>

        <DataTable 
            :value="allLog" 
            :lazy="true" 
            :paginator="true" 
            :rows="10" 
            :rowsPerPageOptions="[10, 20, 50]"
            v-model:filters="filters" 
            ref="dt" 
            dataKey="id" 
            :totalRecords="totalRecords" 
            :loading="loading"
            @page="onPage($event)" 
            @filter="onFilter($event)" 
            filterDisplay="row" 
            responsiveLayout="scroll"
            :globalFilterFields="['descricao', 'responsavel', 'dtOperacao', 'operacao', 'datatable']"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando de {first} a {last} no total de {totalRecords} registros de logs">
          <template #header>
            <div class="
                table-header
                p-d-flex p-flex-column p-flex-md-row p-jc-md-between
              ">
              <h5>Log de Datatables</h5>

              <span class="p-input-icon-left">
                <i class="pi pi-search" />
              </span>
            </div>
          </template>
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column header="Datatable" 
                  field="registro.colums.datatable.nome" 
                  ref="datatable" 
                  filterField="datatable"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown 
                v-model="filterModel.value"
                :options="allDatatables" 
                :filter="true"
                optionLabel="nome" 
                optionValue="nome" 
                placeholder="Escolha o Datatable" 
                @change="filterCallback()"
                >
              </Dropdown>
            </template>
            <template #body="slotProps">
              <span class="p-column-title">Datatable:</span>
              <b>{{ slotProps.data.registro.colums.datatable.nome }}</b>
            </template>
          </Column>
          <Column header="Descrição" 
                  field="descricao" 
                  ref="descricao"  
                  filterField="descricao">
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Pesquisar por descrição" />
            </template>
            <template #body="slotProps">
              <span class="p-column-title">Descrição:</span>
              {{ slotProps.data.descricao }}
            </template>
          </Column>
          <Column header="Responsavél" 
                  field="responsavel" 
                  ref="responsavel" 
                  filterField="responsavel"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Pesquisar por responsavél" />
            </template>
            <template #body="slotProps">
              <span class="p-column-title">Responsavél:</span>
              {{ slotProps.data.responsavel }}
            </template>
          </Column>
          <Column header="Origem" field="ip" >
            <template #body="slotProps">
              <span class="p-column-title">Origem:</span>
              {{ formatIp(slotProps.data.ip) }}
            </template>
          </Column>
          <Column header="Dt. Operação" 
                  field="dtOperacao" 
                  ref="dtOperacao" 
                  filterField="dtOperacao"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="date" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Pesquisar por Data" />
            </template>
            <template #body="slotProps">
              <span class="p-column-title">Dt. Operação:</span>
              {{ $DateTime.formatDateTime24hours(slotProps.data.dtOperacao) }}
            </template>
          </Column>
          <Column header="Operação" 
                  field="operacao" 
                  ref="operacao" 
                  filterField="operacao"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Pesquisar por operação" />
            </template>
            <template #body="slotProps">
              <span class="p-column-title">Operação:</span>
              <span v-if="slotProps.data.operacao === 'POST'" class="product-badge status-post">{{ slotProps.data.operacao
              }}</span>
              <span v-if="slotProps.data.operacao === 'PUT'" class="product-badge status-put">{{ slotProps.data.operacao
              }}</span>

              <span v-if="slotProps.data.operacao === 'DELETE'" class="product-badge status-delete">{{
                slotProps.data.operacao }}</span>
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>
-->
</template>

<script>



import LogService from "../../service/log/log_service";

import Log from "../../models/log";

export default {
  data() {
    return {
      loading: false,
      log: new Log(),
      logService: new LogService(),
      allLog: [],
      allEndPoints: [],
      totalRecords: 0,
      filters: {
        descricao: { value: "", matchMode: "contains" },
        nomeOperador: { value: "", matchMode: "contains" },
        operacao: { value: "", matchMode: "contains" },
        dtOperacao: { value: "", matchMode: "contains" },
        endPoint: { value: "", matchMode: "contains" },
      },
    };
  },
  logService: null,
  datatableService: null,
  created() {
    this.loading = true;
    this.lazyParams = {
      first: 0,
      sortField: null,
      sortOrder: null,
      descricao: "",
      nomeOperador: "",
      operacao: "",
      dtOperacao: "",
      endPoint: "",
    };
    
  },
  mounted() {
    this.findAll();
    this.findEndPoints();
  },
  methods: {
    findAll() {
      this.loading = true;
      //LIMPEZA DE FILTROS
      this.lazyParams.descricao = this.lazyParams.descricao == null ? "" : this.lazyParams.descricao;
      this.lazyParams.nomeOperador = this.lazyParams.nomeOperador == null ? "" : this.lazyParams.nomeOperador;
      this.lazyParams.operacao = this.lazyParams.operacao == null ? "" : this.lazyParams.operacao;
      this.lazyParams.dtOperacao = this.lazyParams.dtOperacao == null ? "" : this.lazyParams.dtOperacao;
      this.lazyParams.endPoint = this.lazyParams.endPoint == null ? "" : this.lazyParams.endPoint;
      // FIM LIMPEZA DE FILTROS
      this.logService.findAll(this.lazyParams).then((data) => {
        this.allLog = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      });
    },
    findEndPoints() {
      this.logService
        .findByPoints()
        .then((data) => {
          this.allEndPoints = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    formatIp(param) {
      let containing = param.indexOf(", tokenType=BearertokenValue=<TOKEN>");
      if (containing > -1) {
        param = param.replace(", tokenType=BearertokenValue=<TOKEN>", "");
      }
      return param;
    },
    
    onPage(event) {
      this.lazyParams.page = event.page;
      this.lazyParams.size = event.rows;
      this.findAll();
    },
    
    onFilter() {
      this.lazyParams.page = 0;
      this.lazyParams.descricao = this.filters.descricao.value;
      this.lazyParams.nomeOperador = this.filters.nomeOperador.value;
      this.lazyParams.operacao = this.filters.operacao.value;
      this.lazyParams.dtOperacao = this.filters.dtOperacao.value;
      this.lazyParams.endPoint = this.filters.endPoint.value;
      this.findAll();
    },
    
  },
};
</script>

<style lang="scss" scoped>
.myBox {
  border: none;
  height: 40px;
  width: 250px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-get {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-post {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-put {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-delete {
    background: #ffcdd2;
    color: #c63737;
  }
}
</style>