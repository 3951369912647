//import { required, email, minLength } from "@vuelidate/validators";

import Usuario from "./usuario"


export default class Log {
  constructor() {
    this.id = null;
    this.descricao = null;
    this.endPoint = null;
    this.nomeOperador = null;
    this.operacao = null;
    this.endereco = true;
    this.dtOperacao = null;
    this.usuario = new Usuario();
    
  }

}
