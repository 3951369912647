import axios from "axios";

import Token from "../../utilities/jwt/Token.js";

import { gerarUrl } from "../../utilities/utils/Pageable";

export default class LogService extends Token {

    findAll(queryParams) {
        queryParams = gerarUrl(queryParams);
        return axios({
            method: 'get',
            url: this.api_url + 'log?' +  queryParams,
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }

    findByPoints() {
        return axios({
            method: 'get',
            url: this.api_url + 'log/points',
            withCredentials: true,
            headers: {
                'Authorization': 'Bearer ' + this.token(),
            },
        }).then(res => res.data);
    }
}
